import React from "react";
// import "./Contact.css";
import { useForm } from "react-hook-form";
import emailjs from "emailjs-com";
import {
  Button,
  FormFeedback,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
function ContactForm() {
  const { register, handleSubmit } = useForm();

  function onSubmit(e) {
    e.preventDefault()
    console.log(e)
     emailjs
       .sendForm(
         "service_2fgdv9q",
         "template_d0popns",
         e.target,
         "user_jZBHfCDbMRK7TvfKaQsUf"
       )
       .then(
         (result) => {
           console.log(result.text);
         },
         (error) => {
           console.log(error.text);
         }
       );
     e.target.reset();
  }

  return (
    <>
      <Form onSubmit={onSubmit}>
        <input
          {...register("email", { required: true, pattern: /\S+@\S+\.\S+/ })}
          type="email"
          name="user_email"
          className="text-primary"
          placeholder="Email"
        />
        <input
          {...register("name", {
            required: true,
            minLength: 0,
            maxLength: 50,
          })}
          type="text"
          name="user_name"
          className="text-primary"
          placeholder="Name"
        />
        <input
          {...register("subject", {
            required: true,
            minLength: 0,
            maxLength: 50,
          })}
          type="text"
          name="subject"
          className="text-primary"
          placeholder="Subject"
        />
        {/* <input
          {...register("subject", {
            required: true,
            minLength: 0,
            maxLength: 50,
          })}
type="textarea" name="message" className="text-primary"
        /> */}
        <FormGroup>
          <Label className="text-muted"></Label>
          <Input
            {...register("description", {
              required: true,
              minLength: 0,
              maxLength: 200,
            })}
            type="textarea"
            name="message"
            className="text-primary"
          />
        </FormGroup>
        <button
          type="submit"
          id="get_in_touch"
          value="Send"
          className="contact-form-button box"
        >
          Get in touch
        </button>
      </Form>
    </>
  );
}

export default ContactForm;
