import React from "react";
import "../scss/style.scss";
import SocialMedia from "./SocialMedia";

const Header = () => {
  return (
    <header className="header">
      <h1 className="heading-primary--main">Engineering </h1>
      <h1 className="heading-primary--sub">Design</h1>
      <p className="paragraph">
        Hi! I'm German. I am a developer and product designer. I can help you
        build a product, feature or website. Look through some of my work and
        experience! If you like what you see and have project you need coded,
        don't hesitate to contact me.
      </p>
      <SocialMedia />
    </header>
  );
};

export default Header;
