import React from 'react';
import '../scss/style.scss';
import ruby from '../scss/assets/ruby.svg';
import solidity from '../scss/assets/solidity.svg';
import figma from '../scss/assets/figma.svg';
import rails from '../scss/assets/rails.png';
import react from '../scss/assets/react.svg';
import javascript from '../scss/assets/javascript.svg';

const About = () => {
  return (
    <div className="about scroll-to-about">
      <div className="resume">
        <h5 className="resume-title title">About me</h5>
        <p className="resume-paragraph p">
          Developer, designer, and overall software maverick. Love digging into
          hard problems.
        </p>
        <a href="https://docs.google.com/document/d/1MJdb4s66Zy8UE77GaACVlIFIYTLwAZrZBhBHpJUAhac/export?format=pdf">
          <div id="rectangle_11_ek2" className="box">
            <div id="see_the_project">Get my resume</div>
          </div>
        </a>
      </div>
      <div className="stack">
        <h6 className="p" id="stack-h">
          Languages
        </h6>
        <ul className="stack-languages p">
          <li className="li-language">
            <img src={javascript} alt="" className="stack-logo" />
            Javascript(ES6)
          </li>
          <li className="li-language">
            <img src={solidity} alt="" className="stack-logo" />
            <p className="p-language">Solidity</p>
          </li>
          <li className="li-language">
            <img src={ruby} alt="" className="stack-logo" />
            <p className="p-language">Ruby</p>
          </li>
        </ul>
        <h6 className="p" id="stack-h">
          Frameworks
        </h6>
        <ul className="stack-frameworks p">
          <li className="li-language">
            <img src={react} alt="" className="stack-logo" />
            <p className="p-language">React</p>
          </li>
          <li className="li-language">
            <img src={rails} alt="" className="stack-logo" />
            <p className="p-language">Ruby On Rails</p>
          </li>
        </ul>
        <h6 className="p" id="stack-h">
          Other
        </h6>
        <ul className="stack-other p">
          <li className="li-language">
            <img src={figma} alt="" className="stack-logo" />
            <p className="p-language">Figma</p>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default About;
