import React from 'react';
import '../scss/style.scss';
import balance from '../scss/assets/balance.png';
import movieoracle from '../scss/assets/movieoracle.png';
import evilglitch from '../scss/assets/evilglitch.png';
import menemtoken from '../scss/assets/menemtoken.png';
import freeminderapp from '../scss/assets/freeminderbotapp.png';
import moneykeeper from '../scss/assets/moneykeeper.png';
import mercadolibre from '../scss/assets/ml2.png';
import deepoe from '../scss/assets/deepoe.png';
import bunny from '../scss/assets/bunny.png';
import demons from '../scss/assets/demonsgaze.png';

const Projects = () => {
  return (
    <section className="projects scroll-to-projects">
      <h1 className="projects-title title">Featured projects</h1>
      <div className="projects-grid1">
        <div className="projects-6">
          <img src={demons} alt="" className="img" />
          <h5 className="project-title shadow-title">Demons Gaze</h5>
          <div id="rectangle_hardhat">
            <div id="see_the_project">Web3</div>
          </div>
          <div id="rectangle_solidity1">
            <div id="see_the_project">Solidity</div>
          </div>
          <div id="rectangle_next">
            <div id="see_the_project">Next</div>
          </div>
          <p className="summarize-p">
            Mercadolibre e-commerce app. Built using Next.js (React & Node),
            Firebase, Stripe, Mercadolibre API, Redux, and TailwindCSS.
          </p>
          <a
            id="project-links"
            href="https://demons-gaze.vercel.app/"
            target="_blank"
            rel="noreferrer"
          >
            <div id="rectangle_11_ek1" className="box">
              <div id="see_the_project">See Live Demo</div>
            </div>
          </a>
          <a
            id="project-links2"
            href="https://github.com/realisticattorney/NFT-SPA-2"
            target="_blank"
            rel="noreferrer"
          >
            <div id="rectangle_11_ek5" className="box">
              <div id="see_the_code">See Code</div>
            </div>
          </a>
        </div>
        <div className="projects-6">
          <img src={bunny} alt="" className="img" />
          <h5 className="project-title shadow-title">Bunny Swap</h5>
          <div id="rectangle_react3">
            <div id="see_the_project">React</div>
          </div>
          <div id="rectangle_solidity1">
            <div id="see_the_project">Solidity</div>
          </div>
          <div id="rectangle_next">
            <div id="see_the_project">Next</div>
          </div>
          <p className="summarize-p">
            Mercadolibre e-commerce app. Built using Next.js (React & Node),
            Firebase, Stripe, Mercadolibre API, Redux, and TailwindCSS.
          </p>
          <a
            id="project-links"
            href="https://dexfi-next-js.vercel.app/"
            target="_blank"
            rel="noreferrer"
          >
            <div id="rectangle_11_ek1" className="box">
              <div id="see_the_project">See Live Demo</div>
            </div>
          </a>
          <a
            id="project-links2"
            href="https://github.com/realisticattorney/DexFi-NextJS"
            target="_blank"
            rel="noreferrer"
          >
            <div id="rectangle_11_ek5" className="box">
              <div id="see_the_code">See Code</div>
            </div>
          </a>
        </div>
        <div className="projects-6">
          <img src={deepoe} alt="" className="img" />
          <h5 className="project-title shadow-title">Deepoe</h5>
          <div id="rectangle_graphql">
            <div id="see_the_project">GraphQL</div>
          </div>
          <div id="rectangle_firebase">
            <div id="see_the_project">TypeScript</div>
          </div>
          <div id="rectangle_react4">
            <div id="see_the_project">React</div>
          </div>
          <div id="rectangle_next">
            <div id="see_the_project">Next</div>
          </div>
          <p className="summarize-p">
            Mercadolibre e-commerce app. Built using Next.js (React & Node),
            Firebase, Stripe, Mercadolibre API, Redux, and TailwindCSS.
          </p>
          <a
            id="project-links"
            href="https://deepoe-only.vercel.app/home"
            target="_blank"
            rel="noreferrer"
          >
            <div id="rectangle_11_ek1" className="box">
              <div id="see_the_project">See Live Demo</div>
            </div>
          </a>
          <a
            id="project-links2"
            href="https://github.com/realisticattorney/deepoe-only"
            target="_blank"
            rel="noreferrer"
          >
            <div id="rectangle_11_ek5" className="box">
              <div id="see_the_code">See Code</div>
            </div>
          </a>
        </div>
        <div className="projects-6">
          <img src={mercadolibre} alt="" className="img" />
          <h5 className="project-title shadow-title">MercadoLibre</h5>
          <div id="rectangle_firebase">
            <div id="see_the_project">Firebase</div>
          </div>
          <div id="rectangle_stripe">
            <div id="see_the_project">Stripe</div>
          </div>
          <div id="rectangle_next">
            <div id="see_the_project">Next</div>
          </div>
          <p className="summarize-p">
            Mercadolibre e-commerce app. Built using Next.js (React & Node),
            Firebase, Stripe, Mercadolibre API, Redux, and TailwindCSS.
          </p>
          <a
            id="project-links"
            href="https://mercado-libre-next-js.vercel.app/"
            target="_blank"
            rel="noreferrer"
          >
            <div id="rectangle_11_ek1" className="box">
              <div id="see_the_project">See Live Demo</div>
            </div>
          </a>
          <a
            id="project-links2"
            href="https://github.com/realisticattorney/MercadoLibre-Next.js"
            target="_blank"
            rel="noreferrer"
          >
            <div id="rectangle_11_ek5" className="box">
              <div id="see_the_code">See Code</div>
            </div>
          </a>
        </div>
        <div className="projects-5">
          <img src={moneykeeper} alt="" className="img" />
          <h5 className="project-title shadow-title">MoneyKeeper</h5>
          <div id="rectangle_rails">
            <div id="see_the_project">Ruby - Rails</div>
          </div>
          <div id="rectangle_react-2">
            <div id="see_the_project">React</div>
          </div>
          <p className="summarize-p">
            A personal tracker for your finances. Server's an API built with
            Rails
          </p>
          <a
            id="project-links"
            href="https://moneykeeperreact.herokuapp.com/main"
            target="_blank"
            rel="noreferrer"
          >
            <div id="rectangle_11_ek1" className="box">
              <div id="see_the_project">See Live Demo</div>
            </div>
          </a>
          <a
            id="project-links2"
            href="https://gist.github.com/realisticattorney/d3180e2820e8b57779bb724b15dd400f"
            target="_blank"
            rel="noreferrer"
          >
            <div id="rectangle_11_ek5" className="box">
              <div id="see_the_code">See Code</div>
            </div>
          </a>
        </div>
        <div className="projects-5">
          <img src={freeminderapp} alt="" className="img" />
          <h5 className="project-title shadow-title">FreeMinder</h5>
          <div id="rectangle_javascript">
            <div id="see_the_project">Javascript</div>
          </div>
          <div id="rectangle_react-2">
            <div id="see_the_project">React</div>
          </div>
          <p className="summarize-p">
            A calendar organizer you set as you were texting to another person
          </p>
          <a
            id="project-links"
            href="https://freeminderbot.netlify.app/"
            target="_blank"
            rel="noreferrer"
          >
            <div id="rectangle_11_ek1" className="box">
              <div id="see_the_project">See Live Demo</div>
            </div>
          </a>
          <a
            id="project-links2"
            href="https://gist.github.com/realisticattorney/a4692c47bf45467fd2c21da976ca7847"
            target="_blank"
            rel="noreferrer"
          >
            <div id="rectangle_11_ek5" className="box">
              <div id="see_the_code">See Code</div>
            </div>
          </a>
        </div>
        <div className="projects-3">
          <img src={menemtoken} alt="" className="img" />
          <h5 className="project-title shadow-title">Menem DeFi</h5>
          <div id="rectangle_react">
            <div id="see_the_project">React</div>
          </div>
          <div id="rectangle_solidity">
            <div id="see_the_project">Solidity</div>
          </div>
          <p className="summarize-p">
            A descentralized bank with its own currency. Launched on Ethereum's
            Ropsten network to be tested free of charge.
          </p>
          <a
            id="project-links"
            href="https://menemdefi.netlify.app/"
            target="_blank"
            rel="noreferrer"
          >
            <div id="rectangle_11_ek1" className="box">
              <div id="see_the_project">See Live Demo</div>
            </div>
          </a>
          <a
            id="project-links2"
            href="https://github.com/realisticattorney/PataconApp"
            target="_blank"
            rel="noreferrer"
          >
            <div id="rectangle_11_ek5" className="box">
              <div id="see_the_code">See Code</div>
            </div>
          </a>
        </div>
        <div className="projects-2">
          <img src={evilglitch} alt="" className="img" />
          <h5 className="project-title shadow-title">Evil Glitch</h5>
          <div id="rectangle_javascript">
            <div id="see_the_project">JavaScript</div>
          </div>
          <p className="summarize-p">
            MVP - Platform game for browsers. Built with Phaser 3 and Javascript
          </p>
          <a
            id="project-links"
            href="https://evilg.netlify.app/"
            target="_blank"
            rel="noreferrer"
          >
            <div id="rectangle_11_ek1" className="box">
              <div id="see_the_project">See Live Demo</div>
            </div>
          </a>
          <a
            id="project-links2"
            href="https://github.com/realisticattorney/JS-Platform"
            target="_blank"
            rel="noreferrer"
          >
            <div id="rectangle_11_ek5" className="box">
              <div id="see_the_code">See Code</div>
            </div>
          </a>
        </div>
        <div className="projects-1">
          <img src={movieoracle} alt="" className="img" />
          <h5 className="project-title shadow-title">Movie Oracle</h5>
          <div id="rectangle_figma">
            <div id="see_the_project">Figma</div>
          </div>
          <p className="summarize-p">
            An oracle for your next movie to watch. Currently a prototype built
            on Figma.
          </p>
          <a
            id="project-links"
            href="https://www.youtube.com/watch?v=7biVodJIQ4o"
            target="_blank"
            rel="noreferrer"
          >
            <div id="rectangle_11_ek1" className="box">
              <div id="see_the_project">See Live Demo</div>
            </div>
          </a>
        </div>
        <div className="projects-4">
          <img src={balance} alt="" className="img" />
          <h5 className="project-title shadow-title">Balance</h5>
          <div id="rectangle_rails">
            <div id="see_the_project">Ruby - Rails</div>
          </div>
          <p className="summarize-p">
            MVP of a social media app focused on the backend structure
          </p>
          <a
            id="project-links"
            href="https://balance.herokuapp.com/"
            target="_blank"
            rel="noreferrer"
          >
            <div id="rectangle_11_ek1" className="box">
              <div id="see_the_project">See Live Demo</div>
            </div>
          </a>
          <a
            id="project-links2"
            href="https://github.com/realisticattorney/Balance"
            target="_blank"
            rel="noreferrer"
          >
            <div id="rectangle_11_ek5" className="box">
              <div id="see_the_code">See Code</div>
            </div>
          </a>
        </div>
      </div>
    </section>
  );
};

export default Projects;
