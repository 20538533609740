import "./scss/style.scss"
import Header from './components/Header'
import Nav from "./components/Nav";
import Projects from "./components/Projects";
import About from "./components/About";
import Contact from "./components/Contact";

function App() {

  const show = true

  return (
    <>
      <Nav />
      <Header />
      <Projects show={show} />
      <About />
      <Contact />
    </>
  );
}

export default App;
