import React from "react";
import "../scss/style.scss";
import twitter from "../scss/assets/Twitter.svg";
import linkedin1 from "../scss/assets/linkedin1.png";
import medium from "../scss/assets/Medium.svg";
import angellist from "../scss/assets/AngelList.svg";
import stack from "../scss/assets/Stack.svg";
import github from "../scss/assets/Github.svg";
import github2 from "../scss/assets/github2.png";
import linkedin2 from "../scss/assets/linkedin2.png";
import stack2 from "../scss/assets/stack2.png";
import twitter2 from "../scss/assets/github2.png";
import angel2 from "../scss/assets/angel2.png";
import medium2 from "../scss/assets/medium2.png";

const SocialMedia = ({ color }) => {
  console.log(color);
  return (
    <section
      className="social-media"
      style={{
        justifyContent: color ? "center" : "hidden",
      }}
    >
      {color ? null : (
        <h6 className="social-media-title">My social networks</h6>
      )}
      <div className="social-media--box">
        <div className="item1">
          <a href="https://twitter.com/contoliaxl/" target="_blank">
            <img
              src={color ? twitter2 : twitter}
              alt="logo"
              className="social-media-logo"
            />
          </a>
        </div>
        <div className="item2">
          <a href="https://www.linkedin.com/in/germanaquila/" target="_blank">
            <img
              src={color ? linkedin2 : linkedin1}
              alt="logo"
              className="social-media-logo"
            />
          </a>
        </div>
        <div className="item3">
          <a href="https://germnaquila.medium.com/" target="_blank">
            <img
              src={color ? medium2 : medium}
              alt="logo"
              className="social-media-logo"
            />
          </a>
        </div>
        <div className="item4">
          <a href="https://angel.co/u/germanaquila" target="_blank">
            <img
              src={color ? angel2 : angellist}
              alt="logo"
              className="social-media-logo"
            />
          </a>
        </div>
        <div className="item5">
          <a
            href="https://stackoverflow.com/users/14688966/german"
            target="_blank"
          >
            <img
              src={color ? stack2 : stack}
              alt="logo"
              className="social-media-logo"
            />
          </a>
        </div>
        <div className="item6">
          <a href="https://github.com/realisticattorney" target="_blank">
            <img
              src={color ? github2 : github}
              alt="logo"
              className="social-media-logo"
            />
          </a>
        </div>
      </div>
    </section>
  );
};

export default SocialMedia;
