import React from "react";
import img from "../scss/GA.svg";
import "../scss/style.scss";
import * as Scroll from "react-scroll";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

const Nav = () => {

    const scrollTo = (whereto) => {
      scroller.scrollTo(whereto, {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    };

  return (
    <nav className="nav">
      <div className="nav__logo-box">
        <img src={img} alt="logo" className="nav__logo-box--logo" />
      </div>
      <div className="nav__navbar">
        <li
          to="test1"
          onClick={() => scrollTo("scroll-to-projects")}
          className="nav__navbar--navlink"
        >
          Projects
        </li>
        <li
          className="nav__navbar--navlink"
          onClick={() => scrollTo("scroll-to-about")}
        >
          About
        </li>
        <li
          className="nav__navbar--navlink"
          onClick={() => scrollTo("scroll-to-contact")}
        >
          Contact
        </li>
      </div>
      <div className="burger">
        <i className="fas fa-bars"></i>
      </div>
    </nav>
  );
};

export default Nav;

            {
              /* <div class="frame">
              <button
                class="custom-btn btn-7 test1"
                to="test1"
                // onClick={() => scrollTo()}
              >
                <span>Get MNM today</span>
              </button>
            </div> */
            }