import React from "react";
import SocialMedia from "./SocialMedia";
import ContactForm from "./ContactForm";
import "../scss/style.scss";

const Contact = () => {
  return (
    <div className="contact scroll-to-contact">
      <h5 className="title" id="white">
        Interested in collaborating?
      </h5>
      <p className="p" id="white">
        If you have an application you are interested in developing, a feature
        that you need built or a project that need coding, I'd love to help you
        with it.
      </p>
      <ContactForm />
      <div className="contact-links">
        <a href="" className="email">
          g.axl.aquila@gmail.com
        </a>
        <SocialMedia color={"white"} />
        <p id="author">© 2021 German Aquila</p>
      </div>
    </div>
  );
};

export default Contact;
